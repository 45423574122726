import React, { useEffect, useState } from 'react';
import { Spin, Alert } from 'antd';
import PolarstepsMediaSelector from './Polarsteps/PolarstepsMedia';
import FavoriteMediaSelector from './FavoriteMedia';
import SpotMediaSelector from './Spot/SpotMedia';
import { UnsplashMedia as UnsplashMediaSelector } from './Unsplash/UnsplashMedia';
import ShutterstockMediaSelector from './Shutterstock/ShutterstockMedia';
import UploadedPhotosMediaSelector from './UploadedPhotos/UploadedPhotosMedia';
import { useFeatureFlag } from 'feature-flags';
import {
    MediaSelectorSourceSelector,
    isSpotSourceWithPhotos,
} from './MediaSelectorSourceSelector';
import type { MediaSource } from './MediaSelectorSourceSelector';
import { useSpotDetails } from 'api/spots.api';

interface MediaSelectorModalProps {
    selectedMedia: CMSMedia | null;
    guideId?: Id;
    location?: PSLocation | null;
    spot?: PSSpot_v15_Minimal | null;
    collectionSpot?: CollectionSpot | null;
    includeVideos: boolean;
    onSelect: (media: CMSMedia) => void;
    shouldShow: boolean;
    disallowedSources: MediaSource[];
    initialMediaSource?: MediaSource;
}
export function MediaSelectorModal({
    selectedMedia,
    guideId,
    location,
    spot,
    collectionSpot,
    includeVideos,
    onSelect,
    shouldShow,
    disallowedSources,
    initialMediaSource,
}: MediaSelectorModalProps) {
    const [ENABLE_UNSPLASH] = useFeatureFlag('ENABLE_UNSPLASH');
    const [ENABLE_SHUTTERSTOCK] = useFeatureFlag('ENABLE_SHUTTERSTOCK');
    const [selectedSource, setSelectedSource] = useState<MediaSource>(
        'POLARSTEPS',
    );
    const { isLoading, fullSpot, errorLoading } = useSpotDetails(spot ?? null);
    useEffect(() => {
        if (initialMediaSource) {
            setSelectedSource(initialMediaSource);
        } else if (fullSpot && isSpotSourceWithPhotos(fullSpot.source)) {
            // Ideally we could receive spot as a prop and not have to do this, but since we only load the details when the modal is shown, we can't do it.
            setSelectedSource('SPOT');
        } else {
            setSelectedSource('POLARSTEPS');
        }
    }, [fullSpot, initialMediaSource]);
    const containerStyle = { display: shouldShow ? 'block' : 'none' };

    if (isLoading) {
        return (
            <div className="media-selector-modal__loading">
                <Spin />
            </div>
        );
    }
    if (errorLoading) {
        return (
            <Alert
                message="Error loading details of spot"
                description={errorLoading}
                type="error"
            />
        );
    }

    const sourceSelector = (
        <MediaSelectorSourceSelector
            spot={fullSpot}
            value={selectedSource}
            onChange={setSelectedSource}
            disallowedSources={disallowedSources}
        />
    );

    // We hide and show using css so when switching sources the scroll is maintained
    return (
        <div style={containerStyle}>
            {!disallowedSources.includes('POLARSTEPS') ? (
                <PolarstepsMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    location={location}
                    onSelect={onSelect}
                    includeVideos={includeVideos}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'POLARSTEPS'}
                />
            ) : null}
            {ENABLE_SHUTTERSTOCK &&
            !disallowedSources.includes('SHUTTERSTOCK') ? (
                <ShutterstockMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    location={location ?? null}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'SHUTTERSTOCK'}
                    spot={fullSpot ?? null}
                />
            ) : null}
            {ENABLE_UNSPLASH && !disallowedSources.includes('UNSPLASH') ? (
                <UnsplashMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    location={location ?? null}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'UNSPLASH'}
                    spot={fullSpot ?? null}
                />
            ) : null}
            {guideId ? (
                <FavoriteMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    includeVideos={includeVideos}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'FAVORITES'}
                />
            ) : null}
            {fullSpot && !disallowedSources.includes('SPOT') ? (
                <SpotMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'SPOT'}
                    spot={fullSpot}
                />
            ) : null}
            {collectionSpot &&
            !disallowedSources.includes('UPLOADED_PHOTOS') ? (
                <UploadedPhotosMediaSelector
                    selectedMedia={selectedMedia}
                    guideId={guideId}
                    onSelect={onSelect}
                    sourceSelector={sourceSelector}
                    isShown={selectedSource === 'UPLOADED_PHOTOS'}
                    collectionSpot={collectionSpot}
                />
            ) : null}
        </div>
    );
}
