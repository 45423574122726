import { createApi } from 'unsplash-js';
import { get } from 'utils/http';
import { BASE_URL } from 'constants/env';
import { Basic } from 'unsplash-js/dist/methods/photos/types';

interface UnsplashSearchResult {
    hasMore: boolean;
    currentPage: number;
    results: Basic[];
}

type UnsplashApi = ReturnType<typeof createApi>;

export type UnsplashPhotoFromSearch = Basic;

export const search = async (
    query: string,
    orientation: MediaOrientationSelectorValue = 'all',
    page = 1,
): Promise<UnsplashSearchResult> => {
    const api = await getApi();

    const result = await api.search.getPhotos({
        query,
        page,
        perPage: 30,
        orientation: orientation !== 'all' ? orientation : undefined,
    });
    if (result.errors) {
        throw new Error(result.errors.join('; '));
    }
    return {
        results: result.response?.results || [],
        currentPage: page,
        hasMore: page < (result.response?.total_pages || 1),
    };
};

/**
 * This should be triggered when a user selects a photo from Unsplash (https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download)
 */
export async function notifyDownload(photo: UnsplashPhotoFromSearch) {
    const api = await getApi();
    await api.photos.trackDownload({
        downloadLocation: photo.links.download_location,
    });
}

let api: UnsplashApi | null = null;
async function getApi() {
    if (!api) {
        const accessKey: string = await get(`${BASE_URL}/cms/unsplash/key`);
        api = createApi({
            accessKey,
        });
    }
    return api;
}
