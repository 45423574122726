import React, { useEffect, useState, useCallback } from 'react';
import { Input, Spin, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from '@react-hook/debounce';
import InfiniteScroll from 'react-infinite-scroller';
import UnsplashMediaItem from './UnsplashMediaItem';
import {
    convertUnsplashMediaToCMSMedia,
    isUnsplashMediaSelected,
} from 'utils/media-utils';
import { useInfiniteScrollGet } from 'utils/use-infinite-scroll-get';
import { notifyDownload, search } from 'api/unsplash.api';
import type { UnsplashPhotoFromSearch } from 'api/unsplash.api';
import MediaOrientationSelector from '../MediaOrientationSelector';
import { parse } from 'utils/error-parser';

interface UnsplashMediaProps {
    selectedMedia: CMSMedia | null;
    guideId?: Id;
    onSelect: (media: CMSMedia) => void;
    isShown: boolean;
    sourceSelector: React.ReactNode;
    spot: PSSpot_v15 | null;
    location: PSLocation | null;
}
export function UnsplashMedia({
    selectedMedia,
    guideId,
    onSelect,
    isShown,
    sourceSelector,
    spot,
    location,
}: UnsplashMediaProps) {
    const [
        orientation,
        setOrientation,
    ] = useState<MediaOrientationSelectorValue>('all');

    const selectMedia = async (media: UnsplashPhotoFromSearch) => {
        // Notifying photo as downloaded as Unsplash requires: https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
        // Right now this is not costing money, so we are being aggressive about it. We could make it more complicated and only trigger
        // the download when the image is actually saved into a guide / destination / anything, but it is not needed. (related slack: https://polarsteps.slack.com/archives/C051DBAF63X/p1737971413590759)
        notifyDownload(media);
        const convertedMedia = await convertUnsplashMediaToCMSMedia(media);
        onSelect(convertedMedia);
    };
    const [filter, setFilter] = useState(spot?.name || location?.name || '');
    const [filterDebounced, setFilterDebounced] = useDebounce(
        spot?.name || location?.name || '',
        300,
    );

    // isActive will be true once the component isShown for the first time, so we only start querying when that happens
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (isShown) {
            setIsActive(true);
        }
    }, [isShown]);

    const apiGetter = useCallback(
        (page: number) => search(filterDebounced, orientation, page),
        [filterDebounced, orientation],
    );

    const {
        isLoading,
        getNewPage,
        hasMoreResults,
        results,
        errorLoadingMessage,
    } = useInfiniteScrollGet(apiGetter);

    useEffect(() => {
        notification.destroy();
        if (errorLoadingMessage) {
            notification.error({
                message: 'Error searching for images',
                description: parse(errorLoadingMessage),
            });
        }
    }, [errorLoadingMessage]);

    return (
        <div style={{ display: isShown ? 'block' : 'none' }}>
            <div className="media-selector__tools">
                {sourceSelector}
                <div className="grow-full-flex ml-sm">
                    <Input
                        className="media-selector-modal__searchbox"
                        placeholder="Search"
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value);
                            setFilterDebounced(e.target.value);
                        }}
                        prefix={
                            <SearchOutlined
                                style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                        }
                    />
                </div>
                <div className="ml-sm">
                    <MediaOrientationSelector
                        value={orientation}
                        onChange={setOrientation}
                    />
                </div>
            </div>

            {isActive ? (
                <div className="media-selector-image-list-container">
                    {/* All photos */}
                    <InfiniteScroll
                        key={`${filterDebounced}__${orientation}`}
                        className="media-selector-image-list"
                        pageStart={0}
                        loadMore={getNewPage}
                        threshold={400}
                        hasMore={!isLoading && hasMoreResults}
                        useWindow={false}
                    >
                        {results.map((photo) => (
                            <UnsplashMediaItem
                                key={photo.id}
                                media={photo}
                                guideId={guideId}
                                onSelect={() => selectMedia(photo)}
                                isSelected={isUnsplashMediaSelected(
                                    photo,
                                    selectedMedia,
                                )}
                            />
                        ))}
                    </InfiniteScroll>

                    {isLoading ? (
                        <div className="media-selector-image-list-loading">
                            <Spin />
                        </div>
                    ) : null}
                    {!results.length && !isLoading && !!filter ? (
                        <div className="media-selector-no-results">
                            No results
                        </div>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
}
